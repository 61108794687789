import {
    FieldNumberWitControlsContainerProps,
    FieldNumberWitControlsContainerState,
} from 'Component/FieldNumberWithControls/FieldNumberWithControls.type';
import {
    FieldNumberWithControlsContainer as SourceFieldNumberWithControlsContainer,
} from 'SourceComponent/FieldNumberWithControls/FieldNumberWithControls.container';
import {formattedInputNumber} from 'Util/InputNumberFormat';

import FieldNumberWithControls from './FieldNumberWithControls.component';

/**
 * Field Number With Controls
 * @class FieldNumberWithControlsContainer
 * @namespace Steinkrueger/Component/FieldNumberWithControls/Container */
export class FieldNumberWithControlsContainer extends SourceFieldNumberWithControlsContainer{
    state: FieldNumberWitControlsContainerState = {
        value: 0,
        isKgUpdated: false,
        isKgUpdatedFromInput: false,
        incrementActive: false,
        decrementActive: false,
    };

    containerFunctions = {
        handleValueChange: this.handleValueChange.bind(this),
        setRef: this.setRef.bind(this),
        setActiveState: this.setActiveState.bind(this),
        removeActiveState: this.removeActiveState.bind(this),
        updateInputValue: this.updateInputValue.bind(this),
        handleChange: this.handleChange.bind(this),
    };

    timeout: NodeJS.Timeout | null = null;

    componentDidUpdate(prevProps: FieldNumberWitControlsContainerProps): void {
        const {
            attr: { min = 0, defaultValue = min } = {},
            priceType,
            attr: { step },
        } = this.props;
        const {
            attr: { defaultValue: prevDefaultValue = 0 } = {},
            attr: { step: prevStep },
            priceType: prevPriceType,
        } = prevProps;
        const { incrementActive, decrementActive } = this.state;

        // @ts-ignore
        if (defaultValue <= 0 || prevDefaultValue <= 0) {
            // eslint-disable-next-line react/no-did-update-set-state
            this.setState({ value: min });
        }

        if ((priceType !== prevPriceType || step !== prevStep) && step) {
            this.setState({ value: step, isKgUpdated: false, isKgUpdatedFromInput: false });
        }

        if (defaultValue < min || prevDefaultValue < min) {
            this.handleInitialLoad(min);
        }

        if (priceType === 'cart' && defaultValue !== prevDefaultValue) {
            this.setState({
                isKgUpdatedFromInput: false,
            });
        }

        if (incrementActive && priceType === prevPriceType) {
            this.timeout = setTimeout(() => {
                this.incrementValue();
                // eslint-disable-next-line no-magic-numbers
            }, 150);
        }

        if (decrementActive && priceType === prevPriceType) {
            this.timeout = setTimeout(() => {
                this.decrementValue();
                // eslint-disable-next-line no-magic-numbers
            }, 150);
        }
    }

    updateInputValue(evt: React.ChangeEvent<HTMLInputElement>): void {
        if (evt.type === 'blur') {
            const numberValue = Number(this.state.value);
            const {
                events: { onChange } = {},
            } = this.props;

            const newValue = this.setValue(numberValue);

            if (typeof onChange === 'function') {
                onChange(Number(newValue));
            }
        } else {
            const formattedValue = formattedInputNumber(evt.target.value);

            this.setState({
                isKgUpdatedFromInput: true,
                isKgUpdated: false,
                value: formattedValue || 0,
            });
        }
    }

    handleChange(value: number | string): void {
        const {
            events: { onChange } = {},
        } = this.props;

        const newValue = this.setValue(value);

        if (typeof onChange === 'function') {
            onChange(Number(newValue));
        }

        this.setState({
            isKgUpdated: true,
            isKgUpdatedFromInput: false,
        });
    }

    handleValueChange(value: number | string): void {
        const {
            events: { onChange } = {},
            priceType,
        } = this.props;

        const newValue = this.setValue(value);

        if (typeof onChange === 'function' && priceType !== 'cart') {
            onChange(Number(newValue));
        }
    }

    setActiveState(direction: string): void {
        const { attr: { step } } = this.props;
        const { value } = this.state;

        if (direction === 'increment' && step) {
            // eslint-disable-next-line no-magic-numbers
            this.handleValueChange(+(+value + +step).toFixed(3));

            this.setState({
                incrementActive: true,
                decrementActive: false,
            });
        }

        if (direction === 'decrement' && step) {
            // eslint-disable-next-line no-magic-numbers
            this.handleValueChange(+(+value - +step).toFixed(3));

            this.setState({
                incrementActive: false,
                decrementActive: true,
            });
        }
    }

    incrementValue(): void {
        const { attr: { step }, priceType } = this.props;
        const { value, incrementActive } = this.state;

        if (step && incrementActive) {
            // eslint-disable-next-line no-magic-numbers
            this.handleValueChange(+(+value + +step).toFixed(3));
        }

        if (this.timeout && priceType !== 'cart') {
            clearTimeout(this.timeout);
        }
    }

    decrementValue(): void {
        const { attr: { step, min }, priceType } = this.props;
        const { value, decrementActive } = this.state;

        if (step && decrementActive && value !== min) {
            // eslint-disable-next-line no-magic-numbers
            this.handleValueChange(+(+value - +step).toFixed(3));
        }

        if (this.timeout && priceType !== 'cart') {
            clearTimeout(this.timeout);
        }
    }

    removeActiveState(): void {
        const {
            events: { onChange } = {},
            priceType,
        } = this.props;

        const { value } = this.state;

        this.setState({
            incrementActive: false,
            decrementActive: false,
        });

        if (typeof onChange === 'function' && priceType === 'cart') {
            onChange(Number(value));
        }
    }

    containerProps() {
        const {
            attr: {
                autoComplete,
                defaultValue,
                ...attr
            } = {},
            value,
            events,
            isDisabled,
            priceType,
            isKg,
        } = this.props;

        const { value: stateValue, isKgUpdated, isKgUpdatedFromInput } = this.state;
        const cartStringValue = defaultValue && !isKgUpdatedFromInput
            ? defaultValue.toString() : stateValue;

        const cartStateValue = priceType !== 'cart' ? stateValue : cartStringValue;

        return {
            attr: {
                ...attr,
                autoComplete,
            },
            value,
            events,
            isDisabled,
            isKg,
            isKgUpdated,
            isKgUpdatedFromInput,
            stateValue: cartStateValue,
            priceType,
        };
    }
}

export default FieldNumberWithControls;
