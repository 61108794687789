/* eslint-disable no-magic-numbers */

/** @namespace Steinkrueger/Util/DateFormat/formattedDate */
export const formattedDate = (date: string): string => {
    const jsDate = new Date(date);
    const d = jsDate.getDate();
    const m = jsDate.getMonth() + 1;

    return `${ d <= 9 ? `0${ d}` : d}.${m <= 9 ? `0${ m}` : m }.`;
};

/** @namespace Steinkrueger/Util/DateFormat/formattedFullDate */
export const formattedFullDate = (date: string): string => {
    const jsDate = new Date(date);
    const d = jsDate.getDate();
    const m = jsDate.getMonth() + 1;
    const y = jsDate.getFullYear();

    return `${ d <= 9 ? `0${ d}` : d}.${m <= 9 ? `0${ m}` : m }.${y}`;
};

/** @namespace Steinkrueger/Util/DateFormat/formattedDayEurope */
export const formattedDayEurope = (date: string | undefined): string => {
    if (!date) {
        return '';
    }

    const jsDate = new Date(date);
    const d = jsDate.getDate();
    const m = jsDate.getMonth() + 1;
    const y = jsDate.getFullYear();

    return `${y}-${m <= 9 ? `0${ m}` : m }-${ d <= 9 ? `0${ d}` : d}`;
};

/** @namespace Steinkrueger/Util/DateFormat/europeTime */
export const europeTime = (earliestDeliveryDay: string = '', earliestDeliveryTime: string = ''): number => {
    const nextDeliveryDay = new Date();
    const nextTomorrowDeliveryDay = new Date(nextDeliveryDay);
    nextTomorrowDeliveryDay.setDate(nextDeliveryDay.getDate() + 1);
    const formattedEarliestDeliveryDay = new Date(earliestDeliveryDay);

    if (formattedEarliestDeliveryDay.getDate() !== nextTomorrowDeliveryDay.getDate()) {
        return -1;
    }

    const today = new Date().toLocaleString('en-US', {timeZone: 'Europe/Berlin'});
    const formattedTomorrow = new Date(today);
    const tomorrow = new Date(formattedTomorrow);
    tomorrow.setDate(tomorrow.getDate());
    const currentEuropeTime = tomorrow.getTime();
    const earliestHours = earliestDeliveryTime ? Number(earliestDeliveryTime.split(':')[0]) : 0;
    const earliestMinutes = earliestDeliveryTime ? Number(earliestDeliveryTime.split(':')[1]) : 0;

    if (Number.isNaN(earliestHours) || !earliestDeliveryTime) {
        return -1;
    }

    const formattedHours = Number.isNaN(earliestHours) ? 0 : earliestHours;
    const formattedMinutes = Number.isNaN(earliestMinutes) ? 0 : earliestMinutes;

    const middayEuropeTime = tomorrow.setHours(formattedHours, formattedMinutes, 0);
    const currentLocalTime = new Date();

    return currentLocalTime.getTime() + middayEuropeTime - currentEuropeTime;
};

/** @namespace Steinkrueger/Util/DateFormat/formatInTwoDigits */
export const formatInTwoDigits = (value: number): string => value.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
});
