import {connect} from 'react-redux';

import AddIcon from 'Component/AddIcon';
import {FieldType} from 'Component/Field/Field.config';
import {FieldReactEvents} from 'Component/Field/Field.type';
import MinusIcon from 'Component/MinusIcon';
import {
    FieldNumberWithControlsComponent as SourceFieldNumberWithControlsComponent,
} from 'SourceComponent/FieldNumberWithControls/FieldNumberWithControls.component';
import {ReactElement} from 'Type/Common.type';
import {DEFAULT_MAX_PRODUCTS} from 'Util/Product/Product.type';
import {RootState} from 'Util/Store/Store.type';

import './FieldNumberWithControlsComponent.override.style.scss';

/** @namespace Steinkrueger/Component/FieldNumberWithControls/Component/mapStateToProps */
export const mapStateToProps = (state: RootState) => ({
    device: state.ConfigReducer.device,
});

/** @namespace Steinkrueger/Component/FieldNumberWithControls/Component/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Steinkrueger/Component/FieldNumberWithControls/Component */
export class FieldNumberWithControlsComponent extends SourceFieldNumberWithControlsComponent {
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    changeInputValue(): void {
        const {
            handleValueChange,
            value,
            attr: { step = 1 },
            stateValue,
        } = this.props;

        const numberValue = +stateValue || +value;
        const increment = Number(step);

        // eslint-disable-next-line no-magic-numbers
        handleValueChange(+(+numberValue + increment).toFixed(3));
    }

    renderInputNumberField(): ReactElement {
        const {
            attr,
            attr: { min = 1, max = DEFAULT_MAX_PRODUCTS, step = 1 },
            value,
            setRef,
            stateValue,
            isDisabled,
            isKg,
            updateInputValue,
            handleChange,
            isKgUpdated,
            isKgUpdatedFromInput,
            priceType,
        } = this.props;

        const numberValue = value ? Number(value) : Number(stateValue);
        const increment = Number(step);

        // eslint-disable-next-line no-magic-numbers
        const defaultIncrement = +step < 1 ? 10 ** (step.toString().length - 2) : 1 / +step;
        const defaultIncrementLength = defaultIncrement.toString().length - 1;

        const kgValue = isKgUpdated || priceType === 'cart'
            ? numberValue.toFixed(defaultIncrementLength)
            : ((Math.floor(defaultIncrement)) / defaultIncrement).toFixed(defaultIncrementLength);
        const kgInputValue = isKgUpdatedFromInput ? stateValue : kgValue;

        const incrementValueKg = (numberValue <= +step) ? Math.ceil(numberValue) : numberValue;
        const decimalIncrement = 1;

        if (isKg && updateInputValue && handleChange) {
            return (
                <>
                    <input
                      block="inputKg"
                      ref={ (elem) => setRef(elem) }
                        // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                      { ...attr }
                        // eslint-disable-next-line react/jsx-no-bind
                      onChange={ (evt) => updateInputValue(evt) }
                        // eslint-disable-next-line react/jsx-no-bind
                      onBlur={ (evt) => updateInputValue(evt) }
                      value={ kgInputValue }
                      type={ FieldType.NUMBER }
                      aria-label={ __('Value') }
                      disabled={ isDisabled }
                      lang="de"
                    />
                    <button
                        // @ts-ignore
                      disabled={ max === 1 || numberValue >= max || isDisabled }
                        // eslint-disable-next-line react/jsx-no-bind
                      onClick={ () => handleChange((incrementValueKg + decimalIncrement)) }
                      aria-label={ __('Add') }
                      type={ FieldType.BUTTON }
                    >
                        <AddIcon block="SubtractButton" isPrimary />
                    </button>
                    <button
                        // @ts-ignore
                      disabled={ numberValue <= min || (numberValue - increment) < min || isDisabled }
                        // eslint-disable-next-line react/jsx-no-bind
                      onClick={ () => handleChange((incrementValueKg - decimalIncrement)) }
                      aria-label={ __('Subtract') }
                      type={ FieldType.BUTTON }
                    >
                        <MinusIcon block="AddButton" isPrimary />
                    </button>
                </>
            );
        }

        return null;
    }

    render(): ReactElement {
        const {
            attr,
            attr: { min = 1, max = DEFAULT_MAX_PRODUCTS, step = 1 },
            value,
            events,
            setRef,
            stateValue,
            isDisabled,
            setActiveState,
            removeActiveState,
            device,
            isKg,
            updateInputValue,
            handleChange,
        } = this.props;

        const numberValue = value ? Number(value) : Number(stateValue);
        const digitsAfterComma = step.toString().split('.')[1]?.length;
        const stringValue = digitsAfterComma
            ? numberValue.toFixed(digitsAfterComma).toString()
            : numberValue.toString();
        const increment = Number(step);

        // eslint-disable-next-line no-magic-numbers
        if (isKg && updateInputValue && handleChange && step === 0.001) {
            return (
                <>
                    { this.renderInputNumberField() }
                </>
            );
        }

        if (!setActiveState || !removeActiveState) {
            return null;
        }

        const isMobile = device ? device.isMobile : false;

        if (isMobile) {
            return (
                <>
                    <input
                      ref={ (elem) => setRef(elem) }
                        // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                      { ...attr }
                        // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                      { ...events as FieldReactEvents<HTMLInputElement> }
                      value={ stringValue }
                      type={ FieldType.NUMBER }
                      readOnly
                      aria-label={ __('Value') }
                      disabled={ isDisabled }
                    />
                    <button
                        // @ts-ignore
                      disabled={ max === 1 || numberValue >= max || isDisabled }
                        // eslint-disable-next-line react/jsx-no-bind
                      onTouchStart={ () => setActiveState('increment') }
                        // eslint-disable-next-line react/jsx-no-bind
                      onTouchEnd={ () => removeActiveState() }
                        // eslint-disable-next-line react/jsx-no-bind
                      onTouchMove={ () => removeActiveState() }
                      aria-label={ __('Add') }
                      type={ FieldType.BUTTON }
                    >
                        <AddIcon block="SubtractButton" isPrimary />
                    </button>
                    <button
                        // @ts-ignore
                      disabled={ numberValue <= min || (numberValue - increment) < min || isDisabled }
                        // eslint-disable-next-line react/jsx-no-bind
                        // eslint-disable-next-line react/jsx-no-bind
                      onTouchStart={ () => setActiveState('decrement') }
                        // eslint-disable-next-line react/jsx-no-bind
                      onTouchEnd={ () => removeActiveState() }
                        // eslint-disable-next-line react/jsx-no-bind
                      onTouchMove={ () => removeActiveState() }
                      aria-label={ __('Subtract') }
                      type={ FieldType.BUTTON }
                    >
                        <MinusIcon block="AddButton" isPrimary />
                    </button>
                </>
            );
        }

        return (
            <>
                <input
                  ref={ (elem) => setRef(elem) }
                    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                  { ...attr }
                    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                  { ...events as FieldReactEvents<HTMLInputElement> }
                  value={ stringValue }
                  type={ FieldType.NUMBER }
                  readOnly
                  aria-label={ __('Value') }
                  disabled={ isDisabled }
                />
                <button
                    // @ts-ignore
                  disabled={ max === 1 || numberValue >= max || isDisabled }
                    // eslint-disable-next-line react/jsx-no-bind,no-magic-numbers
                  onMouseDown={ () => setActiveState('increment') }
                    // eslint-disable-next-line react/jsx-no-bind,no-magic-numbers
                  onMouseUp={ () => removeActiveState() }
                  aria-label={ __('Add') }
                  type={ FieldType.BUTTON }
                >
                    <AddIcon block="SubtractButton" isPrimary />
                </button>
                <button
                    // @ts-ignore
                  disabled={ numberValue <= min || (numberValue - increment) < min || isDisabled }
                    // eslint-disable-next-line react/jsx-no-bind,no-magic-numbers
                  onMouseDown={ () => setActiveState('decrement') }
                    // eslint-disable-next-line react/jsx-no-bind,no-magic-numbers
                  onMouseUp={ () => removeActiveState() }
                  aria-label={ __('Subtract') }
                  type={ FieldType.BUTTON }
                >
                    <MinusIcon block="AddButton" isPrimary />
                </button>
            </>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FieldNumberWithControlsComponent);
