/* eslint-disable no-magic-numbers */

/** @namespace Steinkrueger/Util/InputNumberFormat/formattedInputNumber */
export const formattedInputNumber = (value: string): string => {
    const formattedValue = value.split('.');
    const digitsAfterComma = formattedValue[1];

    if (value[0] === '0' && value[1] !== '.') {
        return value.substring(1);
    }

    if (digitsAfterComma && digitsAfterComma.length > 3) {
        return `${formattedValue[0]}.${digitsAfterComma.substring(0, 3)}`;
    }

    return value;
};
