import { Field } from '@tilework/opus';

import { MyAccountQuery as SourceMyAccountQuery } from 'SourceQuery/MyAccount.query';
import {CustomerFieldsTotal} from 'SourceQuery/MyAccount.type';

import {
    CustomerFieldsMerged,
    deliveryDateWeekday,
    deliveryDateWeekdayFields,
} from './MyAccount.type';

/** @namespace Steinkrueger/Query/MyAccount/Query */
export class MyAccountQuery extends SourceMyAccountQuery {
    _getCustomerFields(): CustomerFieldsTotal<CustomerFieldsMerged> {
        return [
            new Field<'created_at', string>('created_at'),
            new Field<'confirmation_required', boolean>('confirmation_required'),
            new Field<'group_id', number>('group_id'),
            new Field<'prefix', string>('prefix'),
            new Field<'firstname', string>('firstname'),
            new Field<'middlename', string>('middlename'),
            new Field<'lastname', string>('lastname'),
            new Field<'suffix', string>('suffix'),
            new Field<'email', string>('email'),
            new Field<'default_billing', string>('default_billing'),
            new Field<'default_shipping', string>('default_shipping'),
            new Field<'dob', string>('dob'),
            new Field<'taxvat', string>('taxvat'),
            new Field<'id', number>('id'),
            new Field<'is_subscribed', boolean>('is_subscribed'),
            new Field<'company_availability', number[]>('company_availability'),
            this._getDeliveryDateWeekdayField(),
            this._getAddressesField(),
            this._getDeliveryDateField(),
        ];
    }

    _getDeliveryDateWeekdayField(): Field<'delivery_date_weekday', deliveryDateWeekday, true> {
        return new Field<'delivery_date_weekday', deliveryDateWeekday, true>('delivery_date_weekday', true)
            .addFieldList(this._getDeliveryDateWeekdayFields());
    }

    _getDeliveryDateWeekdayFields(): deliveryDateWeekdayFields {
        return [
            new Field<'weekday', number | string>('weekday'),
            new Field<'is_available', boolean>('is_available'),
        ];
    }

    _getDeliveryDateField(): Field<'delivery_date', string> {
        return new Field<'delivery_date', string>('delivery_date', false);
    }

    _updateDeliveryDateField(): Field<'deliveryDate', string> {
        return new Field<'deliveryDate', string>('deliveryDate', false);
    }
}

export default new MyAccountQuery();
